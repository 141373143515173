import Vue from "vue"
import Router from 'vue-router'
Vue.use(Router);

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



export default new Router({
  routes: [
    {
      path: "/",
      name: "Layout",
      component: () => import("@/views/Layout/index.vue"),
      redirect: "Home",
      meta: { title: "首页" },
      children: [
        {
          // 首页
          path: "Home",
          name: "Home",
          component: () => import("@/views/home/index.vue"),
          meta: { title: "首页" }
        },
        {
         
          path: "Column",
          name: "Column",
          component: () => import("@/views/column/index.vue"),
          meta: { title: "栏目列表" }
        },
        {
        
          path: "searchColumn",
          name: "searchColumn",
          component: () => import("@/views/searchColumn/index.vue"),
          meta: { title: "查询列表" }
        },
        {
          
          path: "Detail",
          name: "Detail",
          component: () => import("@/views/detail/index.vue"),
          meta: { title: "栏目列表" }
        },
      ]
    },
  ]
})