// 引入axios
import axios from 'axios';
// 引入路由
import { Message } from 'element-ui';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
console.log( process.env.VUE_APP_BASE_URL," process.env.VUE_APP_BASE_URL")
axios.defaults.timeout = 30000;
axios.defaults.withCredentials = true;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


// interceptors 请求拦截器
axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// interceptors 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data?.code >= 300) {
      Message.error(response.data.msg);
      return Promise.reject(response.data);
    } else {
      return Promise.resolve(response.data);
    }
  },
  (error) => {
    let { response } = error;
    if (response) {
      switch (response.status) {
        case 404:
          Message.error("无法找到资源路径");
          break;
        case 500:
          Message.error("服务器错误，请联系管理员！");
          break;
      }
    } else {
      if (!window.navigator.onLine) {
        return Message.error("请检查您的网络");
      } else if (error.message.indexOf("timeout") > -1) {
        return Message.error("请求超时");
      } else if (error.message == "canceled") {
        return Promise.reject(error);
      } else {
        return Message.error("服务器错误，请联系管理员！");
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
