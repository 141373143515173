import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

// 初始化状态
const state = {

};
// 监听
const getters = {

};
// 修改状态
const mutations = {

};
// 异步修改状态
const actions = {};
// 容器
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  strict: true // 只能通过 mutation 来更改状态，mutation不支持异步
});
