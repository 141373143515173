import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
// 引入特殊字体
import "./assets/style/font.css"
// 引入公共基础样式
import './assets/style/reset.css';
import './assets/style/common.less';
import './assets/style/element-ui.less';

// 引入并使用element-ui
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
Vue.use(ElementUI);

// 引入路由
import router from '@/router';
// 引入vuex
import store from '@/store/index.js';


// 引入http请求并挂载到Vue全局
import http from "@/utils/http.js"
Vue.prototype.$http = http;
Vue.prototype.$imgUrl='http://www.jsjtgs.cn:9001/api/image'

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')


